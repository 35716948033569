import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppServiceService } from 'src/app/api/app-service.service';
import { ToppingsPage } from '../toppings/toppings.page';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.page.html',
  styleUrls: ['./cart.page.scss'],
})
export class CartPage implements OnInit {

  @Input() highlightUnavailableDishes:boolean = false;

  public couponAdded = false;
  public isheighok = true;
  coupon: any = '';
  public isCouponStatusChecking = false;

  // bar mode system properties
  public isDineInSelected: boolean = false;
  public isBarModeEnabled:boolean = false;

  public makePayment:boolean = false;
  // unavailable dishes to display highlight when not available for selected order time
  public unavailableDishesIds:number[] = [];

  constructor(public modalController: ModalController, public appService: AppServiceService, public translate:TranslateService) {
    if (this.appService.appObject.selectedCoupon.length > 0) {
      this.coupon = this.appService.appObject.selectedCoupon[0]['coupon_code'];
      this.couponAdded = true;
    }

  }

  ngOnInit() {
    this.appService.cartIsOpen = true;
    // console.log(this.appService.appObject.selectedRestaurantDetails);
    // bar mode changes
    if(this.appService.appObject.selectedRestaurantDetails && this.appService.appObject.selectedRestaurantDetails.is_bar_mode === 'true'){
      this.isBarModeEnabled = true;
      if(this.appService.appObject.selectedDeliveryMethod === 'dine_in') this.isDineInSelected = true;
    }
    // end bar mode changes
    const newThis = this;
    setInterval(() => {
      newThis.isheight();
    }, 500);

    // this will trigger when user click on categories unavailable error card in checkout page
    // this will highlight dishes in cart which are not available for the order date and time
    if(this.highlightUnavailableDishes){
      this.appService.unavailableDishes.forEach((dish) =>{
        this.unavailableDishesIds.push(dish.dish_id);
      });

    }
  }

  public isheight(): void {

    if (document.querySelectorAll<HTMLElement>('.popup-container').length) {


      const element = document.querySelectorAll<HTMLElement>('.popup-container')[0];
      const element2 = document.querySelectorAll<HTMLElement>('.popup-container-inner')[0];

      if ((element && element2) && element.offsetHeight < element2.offsetHeight) {
        this.isheighok = false;
      } else {
        this.isheighok = true;
      }

    }
  }

  public dismiss() {
    this.appService.cartIsOpen = false;
    this.appService.hideCartButton = false;
    //this.appService.getRestaurantBySlug(this.appService.appObject.selectedRestaurant);
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    if (!this.appService.appObject.orderObject.cart_summery.min_reached && this.appService.appObject.orderObject.cart_summery.min_amount > 0) {
      this.appService.routeToSelectedRestaurant();
    }
    this.modalController.dismiss({
      'dismissed': true,
      'array': 'kk'
    });
  }

  async removeCoupon() {
    this.couponAdded = false;
    this.coupon = '';
    this.appService.appObject.selectedCoupon = [];
    this.appService.calculateCartAmounts();
  }

  async addCoupon() {

    if(this.coupon === ""){
      return false;
    }

    if(!this.appService.appObject.user || this.appService.appObject.user.type !== 'registered'){
      this.translate.get('key.in_order_to_user_coupons').toPromise().then((response) => {
        this.appService.showToast(response,'danger');
      });
      return;
    }

    this.isCouponStatusChecking = true;
    await this.appService.addCoupon(this.coupon).then(data => {
      if (data.status) {

        this.couponAdded = true;
        //set coupon details
        this.appService.appObject.selectedCoupon = [];
        data.data['coupon_code'] = this.coupon;
        this.appService.appObject.selectedCoupon.push(data.data);
        this.appService.calculateCartAmounts();
      } else {
        this.couponAdded = false;
        this.coupon = '';
        this.appService.showAlert('error','',data.msg);
      }
      this.isCouponStatusChecking = false;
    }, error => {
      this.isCouponStatusChecking = false;
      this.appService.appObject.isLoading = false;
    });

  }

  async incDish(dish) {
    dish.count++;
    this.appService.calculateCartAmounts();
  }


  async decDish(dish) {
    if (dish.count > 1) {
      dish.count--;
      this.appService.calculateCartAmounts();
    }
  }

  async remove(index) {
    this.appService.appObject.orderObject.cart.splice(index, 1);
    this.appService.appObject.orderObjectDup.cart.splice(index, 1);
    this.appService.calculateCartAmounts();
    if (this.appService.appObject.orderObject.cart.length == 0) {
      this.dismiss();
    }
  }

  async edit(index, dish) {

    this.appService.selectedDish.size_id = dish.size_info.size_id;
    this.appService.editing_cart_item = index;

    this.appService.selectedDish.selectedTopings = dish.size_info.topping_info;
    this.appService.selectedDish.fullDishInfo = JSON.parse(JSON.stringify(this.appService.appObject.orderObjectDup.cart[index]));

    this.modalController.dismiss({
      'dismissed': true,
      'array': 'kk'
    });
    this.appService.hideCartButton = true;
    const modal = await this.modalController.create({
      component: ToppingsPage,
      cssClass: 'custom-popup'
    });

    await modal.present();

  }

  onclickAny() {
    console.log('hi');
  }

  async makeFinalPayment(){
    if(this.appService.hasNewDishesInCart()){
      if(this.appService.appObject.orderObject.save_items && this.appService.appObject.orderObject.table_order_id){
        await this.appService.updateKitchenOrder();
        // console.log('Kitchen Order Updated');
      }else{
        await this.appService.sendToKitchen(false);
        // console.log('Kitchen Order Created');
      }
    }
    this.makePayment = true;
  }

}
